import config from 'config';

export const braSizeHeadersMap = (intl) => ({
  size: intl.formatMessage({
    id: 'site_product_option.size',
    defaultMessage: 'Size',
  }),
  a: 'A',
  b: 'B',
  c: 'C',
  d: 'D',
  'd+': 'D+',
});

export const sizeGuideHeadersMapMen = (intl) => ({
  size: intl.formatMessage({
    id: 'site_product_option.size',
    defaultMessage: 'Size',
  }),
  chest: intl.formatMessage({
    id: 'site_product_option.chest',
    defaultMessage: 'Chest',
  }),
  waist: intl.formatMessage({
    id: 'site_product_option.waist',
    defaultMessage: 'Waist',
  }),
  lowHip: intl.formatMessage({
    id: 'site_product_option.low_hip',
    defaultMessage: 'Low Hip',
  }),
  inseam: intl.formatMessage({
    id: 'site_product_option.inseam',
    defaultMessage: 'Inseam',
  }),
});

export const inseamGuideHeadersMap = (intl) => ({
  inseam: intl.formatMessage({
    id: 'site_product_option.inseam',
    defaultMessage: 'Inseam',
  }),
  short: intl.formatMessage({
    id: 'site_product_option.short',
    defaultMessage: 'Short',
  }),
  regular: intl.formatMessage({
    id: 'site_product_option.regular',
    defaultMessage: 'Regular',
  }),
  tall: intl.formatMessage({
    id: 'site_product_option.tall',
    defaultMessage: 'Tall',
  }),
});

export const sizeGuideHeadersMap = (intl) => ({
  size: intl.formatMessage({
    id: 'site_product_option.size',
    defaultMessage: 'Size',
  }),
  bust: intl.formatMessage({
    id: 'site_product_option.bust',
    defaultMessage: 'Bust',
  }),
  waist: intl.formatMessage({
    id: 'site_product_option.waist',
    defaultMessage: 'Waist',
  }),
  hips: intl.formatMessage({
    id: 'site_product_option.hips',
    defaultMessage: 'Hips',
  }),
  us: intl.formatMessage({
    id: 'site_product_option.us',
    defaultMessage: 'US',
  }),
  ukAndAus: intl.formatMessage({
    id: 'site_product_option.uk-aus',
    defaultMessage: 'UK/AUS',
  }),
  eu: intl.formatMessage({
    id: 'site_product_option.eu',
    defaultMessage: 'EU',
  }),
});

export const fabricChartHeadersMap = (intl) => ({
  fabletics: intl.formatMessage({
    id: 'site_product_option.fabletics',
    defaultMessage: 'Fabletics',
  }),
  size: intl.formatMessage({
    id: 'site_product_option.size',
    defaultMessage: 'Size',
  }),
  bust: intl.formatMessage({
    id: 'site_product_option.bust',
    defaultMessage: 'Bust',
  }),
  waist: intl.formatMessage({
    id: 'site_product_option.waist',
    defaultMessage: 'Waist',
  }),
  hips: intl.formatMessage({
    id: 'site_product_option.hips',
    defaultMessage: 'Hips',
  }),
});

export const shoeGuideHeadersMap = (intl) => ({
  us: intl.formatMessage({
    id: 'site_product_option.us',
    defaultMessage: 'US',
  }),
  ukAndAus: intl.formatMessage({
    id: 'site_product_option.uk-aus',
    defaultMessage: 'UK/AUS',
  }),
  eu: intl.formatMessage({
    id: 'site_product_option.eu',
    defaultMessage: 'EU',
  }),
  inches: intl.formatMessage({
    id: 'site_product_option.inches',
    defaultMessage: 'Inches',
  }),
  mm: intl.formatMessage({
    id: 'site_product_option.millimeters',
    defaultMessage: 'MM',
  }),
});

export const conversionChartHeadersMap = (intl) => ({
  fabletics: intl.formatMessage({
    id: 'site_product_option.fabletics',
    defaultMessage: 'Fabletics',
  }),
  us: intl.formatMessage({
    id: 'site_product_option.us',
    defaultMessage: 'US',
  }),
  ukAndAus: intl.formatMessage({
    id: 'site_product_option.uk-aus',
    defaultMessage: 'UK/AUS',
  }),
  eu: intl.formatMessage({
    id: 'site_product_option.eu',
    defaultMessage: 'EU',
  }),
});

export const braSizesUS = [
  ['30', 'XXS/XS', 'XS', 'XS', 'S', '-'],
  ['32', 'XS', 'XS/S', 'S', 'S', 'M'],
  ['34', 'S', 'S', 'M', 'M', 'L'],
  ['36', 'M', 'M', 'L', 'L', 'XL'],
  ['38', 'L', 'L/XL', 'XL', 'XL/XXL', 'XL/XXL'],
  ['40', '-', 'XXL/1X', 'XXL/1X', '1X/2X', '2X/3X'],
  ['42', '-', '1X/2X', '1X/2X', '2X/3X', '3X/4X'],
  ['44', '-', '-', '3X/4X', '3X/4X', '4X'],
  ['46', '-', '-', '4X', '4X', '-'],
];

export const braSizesEU = [
  ['65', 'XXS/XS', 'XS', 'XS', 'S', '-'],
  ['70', 'XS', 'XS/S', 'S', 'S', 'M'],
  ['75', 'S', 'S', 'M', 'M', 'L'],
  ['80', 'M', 'M', 'L', 'L', 'XL'],
  ['85', 'L', 'L/XL', 'XL', 'XL/XXL', 'XL/XXL'],
  ['90', '-', 'XXL/1X', 'XXL/1X', '1X/2X', '2X/3X'],
  ['95', '-', '1X/2X', '1X/2X', '2X/3X', '3X/4X'],
  ['100', '-', '-', '3X/4X', '3X/4X', '4X'],
  ['105', '-', '-', '4X', '4X', '-'],
];

export const inseamGuideSizesInchesMap = (intl) => [
  [
    intl.formatMessage({
      id: 'site_product.legging_size',
      defaultMessage: 'Legging',
    }),
    '25',
    '27',
    '29',
  ],
  [
    intl.formatMessage({
      id: 'site_product.pant_size',
      defaultMessage: 'Pant',
    }),
    '29',
    '31',
    '33',
  ],
];

export const inseamGuideSizesCmMap = (intl) => [
  [
    intl.formatMessage({
      id: 'site_product.legging_size',
      defaultMessage: 'Legging',
    }),
    '64',
    '69',
    '74',
  ],
  [
    intl.formatMessage({
      id: 'site_product.pant_size',
      defaultMessage: 'Pant',
    }),
    '74',
    '79',
    '84',
  ],
];

export const sizeChartSizesMenInches = [
  ['XS (28-30)', '31-34', '25-28', '32-34', '31'],
  ['S (30-32)', '35-38', '28-31', '35-38', '31'],
  ['M (32-34)', '38-41', '31-34', '38-41', '31'],
  ['L (34-36)', '41-44', '34-37', '41-44', '31'],
  ['XL (38)', '44-48', '37-41', '44-48', '31'],
  ['XXL (40)', '48-52', '41-45', '48-52', '31'],
];

export const sizeChartSizesMenCm = [
  ['XS (28-30)', '79-86', '64-71', '81-86', '79'],
  ['S (30-32)', '89-97', '71-79', '89-97', '79'],
  ['M (32-34)', '97-104', '79-86', '97-104', '79'],
  ['L (34-36)', '104-112', '86-94', '104-112', '79'],
  ['XL (38)', '112-122', '94-104', '112-122', '79'],
  ['XXL (40)', '122-132', '104-114', '122-132', '79'],
];

export const sizeChartSizesInches = [
  ['XXS', '30-31.5', '24-25', '33-35', '0-2', '4-6', '32-34'],
  ['XS', '32-33.5', '25.5-26.5', '36-37', '4', '8', '36'],
  ['S', '34-35.5', '27-28', '37.5-38.5', '6', '10', '38'],
  ['M', '36-38', '29-31', '39-41', '8', '12', '40'],
  ['L', '39-41', '32-34', '42-44', '10', '14-16', '42-44'],
  ['XL', '42-44', '35-37', '45-47', '12-14', '18-20', '44-46'],
  ['XXL', '44-46', '37-39', '47-49', '14-16', '22-24', '46-48'],
  ['1X', '46-48', '39-41', '49-51', '16-18', '26', '50'],
  ['XXL/1X', '45-48', '38-41', '48-51', '16-18', '20-22', '48-50'],
  ['2X', '48-50', '41-43', '51-53', '18-20', '28', '52'],
  ['3X', '50-52', '43-45', '53-55', '20-22', '30', '54'],
  ['4X', '52-54', '45-47', '55-57', '22-24', '32', '56'],
  ['5X', '55-59', '48-52', '58-62', '24-26', '34', '60'],
  ['6X', '60-64', '53-57', '63-67', '26-28', '36', '62'],
];

export const sizeChartSizesCm = [
  ['XXS', '76-80', '61-63.5', '84-89', '0-2', '4-6', '32-34'],
  ['XS', '81.5-85', '65-67.5', '91.5-94', '4', '8', '36'],
  ['S', '86.5-90', '68.5-71', '95.5-98', '6', '33-35', '38'],
  ['M', '91.5-96.5', '73.5-78.5', '99-104', '8', '12', '40'],
  ['L', '99-104', '81.5-86.5', '106.5-112', '10', '14-16', '42-44'],
  ['XL', '106.5-112', '89-94', '114.5-119.5', '12-14', '18-20', '44-46'],
  ['XXL', '112-117', '94-99', '119.5-124.5', '14-16', '22-24', '46-48'],
  ['1X', '99-104', '109-114.5', '124.5-129.5', '16-18', '26', '50'],
  ['2X', '122-127', '104-109', '129.5-134.5', '18-20', '28', '52'],
  ['3X', '127-132', '109-114.5', '134.5-139.5', '20-22', '30', '54'],
  ['4X', '132-137', '114.5-119.5', '139.5-145', '22-24', '32', '56'],
  ['5X', '140-150', '122-132', '147-157', '24-26', '34', '60'],
  ['6X', '152-162', '135-145', '160-170', '26-28', '36', '62'],
];

export const inseamChartInchesMap = (intl) => [
  [
    intl.formatMessage({
      id: 'site_product.inseam_short',
      defaultMessage: 'Short',
    }),
    intl.formatMessage({
      id: 'site_product.inseam_short_size',
      defaultMessage: 'Up to 5’3"',
    }),
  ],
  [
    intl.formatMessage({
      id: 'site_product.inseam_regular',
      defaultMessage: 'Regular',
    }),
    intl.formatMessage({
      id: 'site_product.inseam_regular_size',
      defaultMessage: 'From 5’4" to 5’6"',
    }),
  ],
  [
    intl.formatMessage({
      id: 'site_product.inseam_tall',
      defaultMessage: 'Tall',
    }),
    intl.formatMessage({
      id: 'site_product.inseam_tall_size',
      defaultMessage: '5’7" and above',
    }),
  ],
];

export const seamlessChartSizes = [
  ['XS', '2-4', '32-33.5', '25.5-26.5', '36-37'],
  ['S', '4-6', '34-35.5', '27-28', '37.5-38.5'],
  ['M', '6-8', '36-38', '29-31', '39-41'],
  ['L', '8-10', '39-41', '32-34', '42-44'],
  ['XL', '12-14', '42-44', '35-38', '45-47'],
];

export const sculptKnitChartSizes = [
  ['XS', '2-4', '32-33.5', '25.5-26.5', '36-37'],
  ['S', '4-6', '34-35.5', '27-28', '37.5-38.5'],
  ['M', '6-8', '36-38', '29-31', '39-41'],
  ['L', '8-10', '39-41', '34-34', '42-44'],
];

export const conversionChartSizes = [
  ['XXS', '0-2', '4-6', '32-34'],
  ['XS', '4', '8', '36'],
  ['S', '6', '10', '38'],
  ['M', '8', '12', '40'],
  ['L', '10', '14-16', '42-44'],
  ['XL', '12-14', '18-20', '44-46'],
  ['XXL', '14-16', '22-24', '46-48'],
  ['1X', '16-18', '26', '50'],
  ['2X', '18-20', '28', '52'],
  ['3X', '20-22', '30', '54'],
  ['4X', '22-24', '32', '56*'],
  ['5X', '24-26', '34', '60'],
  ['6X', '26-28', '36', '62'],
];

export const shoeGuideSizes = [
  ['6', '4', '36.5', '9.45', '240'],
  ['6.5', '4.5', '37', '9.62', '244'],
  ['7', '5', '37.5', '9.78', '248'],
  ['7.5', '5.5', '38', '9.95', '253'],
  ['8', '6', '38.5', '10.11', '257'],
  ['8.5', '6.5', '39', '10.28', '261'],
  ['9', '7', '40', '10.45', '265'],
  ['9.5', '7.5', '40.5', '10.61', '270'],
  ['10', '8', '41', '10.78', '274'],
  ['10.5', '8.5', '41.5', '10.95', '278'],
  ['11', '9', '42', '11.11', '282'],
];

export const sizeChartDataSeamlessInches = [
  ['XS/S', '32-36', '25-29', '33-39', '4-6', '8-10', '36-38'],
  ['M/L', '36-42', '29-35', '39-44', '8-10', '12-16', '40-44'],
  ['XL/XXL', '42-46', '35-39', '44-49', '12-14', '18-22', '44-50'],
  ['1X/2X', '46-50', '39-43', '49-53', '16-18', '24-28', '52-56'],
  ['3X/4X', '50-54', '43-47', '53-57', '20-24', '30-32', '56-58'],
  ['3X/4X', '55-59', '48-52', '58-62', '26-28', '34-36', '60-62'],
];

export const sizeChartDataSeamlessCms = [
  ['XS/S', '81-91', '64-74', '84-99', '4-6', '8-10', '36-38'],
  ['M/L', '91-107', '74-89', '99-112', '8-10', '12-16', '40-44'],
  ['XL/XXL', '107-117', '74-99', '112-124', '12-14', '18-22', '44-50'],
  ['1X/2X', '117-127', '99-109', '124-135', '16-18', '24-28', '52-56'],
  ['3X/4X', '127-137', '109-119', '135-145', '20-24', '30-32', '56-58'],
  ['3X/4X', '140-150', '122-132', '147-157', '26-28', '34-36', '60-62'],
];

export const braSupportMap = (intl) => ({
  L: intl.formatMessage({
    id: 'app_filters.category_bras_light_support',
    defaultMessage: 'Light Support',
  }),
  M: intl.formatMessage({
    id: 'app_filters.category_bras_medium_support',
    defaultMessage: 'Medium Support',
  }),
  H: intl.formatMessage({
    id: 'app_filters.category_bras_high_support',
    defaultMessage: 'High Support',
  }),
});

const sizeDomainMaps = config.get('public.brand.sizeDomainMaps');

const sizeByDomain = ({ tld }, sizeMap) => {
  switch (tld) {
    case '.co.uk':
    case '.de':
    case '.nl':
      return sizeMap.EU;
    case '.fr':
    case '.es':
      return sizeMap.FR_ES;
    default:
      return sizeMap.US;
  }
};

export const getSizes = ({ domain, gender, category }) => {
  const hasExistingSizeMapForGender = sizeDomainMaps[gender];
  const hasExistingSizeMapForCategory = sizeDomainMaps[gender]?.[category];

  if (hasExistingSizeMapForCategory) {
    return sizeByDomain(domain, sizeDomainMaps[gender][category]);
  }

  if (hasExistingSizeMapForGender) {
    return sizeByDomain(domain, sizeDomainMaps[gender].apparel);
  }
  const defaultGender = Object.keys(sizeDomainMaps)[0];
  return sizeByDomain(domain, sizeDomainMaps[defaultGender].apparel);
};

export const sizeByKey = ({ domain, gender, category, key }) => {
  const sizes = getSizes({ domain, gender, category });
  return sizes[key];
};

export const sizeChartDataAllCollections = {
  BUST: {
    XS: { imperial: '32-33.5', metric: '81-85' },
    S: { imperial: '34-35.5', metric: '86-90' },
    M: { imperial: '36-38', metric: '91-96' },
    L: { imperial: '39-41', metric: '99-104' },
    XL: { imperial: '42-44', metric: '107-112' },
    XXL: { imperial: '44-46', metric: '112-117' },
    '1X': { imperial: '46-48', metric: '117-122' },
    '2X': { imperial: '48-50', metric: '122-127' },
    '3X': { imperial: '50-52', metric: '127-132' },
    '4X': { imperial: '52-54', metric: '132-137' },
    '5X': { imperial: '55-59', metric: '140-150' },
    '6X': { imperial: '60-64', metric: '152-162' },
  },
  WAIST: {
    XS: { imperial: '25.5-26.5', metric: '65-67' },
    S: { imperial: '27-28', metric: '69-71' },
    M: { imperial: '29-31', metric: '74-79' },
    L: { imperial: '32-34', metric: '81-86' },
    XL: { imperial: '35-37', metric: '89-94' },
    XXL: { imperial: '37-39', metric: '94-99' },
    '1X': { imperial: '39-41', metric: '99-104' },
    '2X': { imperial: '41-43', metric: '104-109' },
    '3X': { imperial: '43-45', metric: '109-114' },
    '4X': { imperial: '45-47', metric: '114-119' },
    '5X': { imperial: '48-52', metric: '122-132' },
    '6X': { imperial: '53-57', metric: '135-145' },
  },
  HIP: {
    XS: { imperial: '33-35', metric: '84-89' },
    S: { imperial: '37.5-38.5', metric: '95-98' },
    M: { imperial: '39-41', metric: '99-104' },
    L: { imperial: '42-44', metric: '107-112' },
    XL: { imperial: '45-47', metric: '114-119' },
    XXL: { imperial: '47-49', metric: '119-124' },
    '1X': { imperial: '49-51', metric: '124-129' },
    '2X': { imperial: '51-53', metric: '129-134' },
    '3X': { imperial: '53-55', metric: '134-140' },
    '4X': { imperial: '55-57', metric: '140-145' },
    '5X': { imperial: '58-62', metric: '147-157' },
    '6X': { imperial: '63-67', metric: '160-170' },
  },
  US: {
    XS: { imperial: '2-4', metric: '2-4' },
    S: { imperial: '6', metric: '6' },
    M: { imperial: '8', metric: '8' },
    L: { imperial: '10', metric: '10' },
    XL: { imperial: '12-14', metric: '12-14' },
    XXL: { imperial: '14-16', metric: '14-16' },
    '1X': { imperial: '16-18', metric: '16-18' },
    '2X': { imperial: '18-20', metric: '18-20' },
    '3X': { imperial: '20-22', metric: '20-22' },
    '4X': { imperial: '22-24', metric: '22-24' },
    '5X': { imperial: '24-26', metric: '24-26' },
    '6X': { imperial: '26-28', metric: '26-28' },
  },
  'UK/AUS': {
    XS: { imperial: '8', metric: '8' },
    S: { imperial: '10', metric: '10' },
    M: { imperial: '12', metric: '12' },
    L: { imperial: '14-16', metric: '14-16' },
    XL: { imperial: '18-20', metric: '18-20' },
    XXL: { imperial: '22-24', metric: '22-24' },
    '1X': { imperial: '26', metric: '26' },
    '2X': { imperial: '28', metric: '28' },
    '3X': { imperial: '30', metric: '30' },
    '4X': { imperial: '32', metric: '32' },
    '5X': { imperial: '34', metric: '34' },
    '6X': { imperial: '36', metric: '36' },
  },
  EU: {
    XS: { imperial: '36', metric: '36' },
    S: { imperial: '38', metric: '38' },
    M: { imperial: '40', metric: '40' },
    L: { imperial: '42-44', metric: '42-44' },
    XL: { imperial: '44-46', metric: '44-46' },
    XXL: { imperial: '50', metric: '50' },
    '1X': { imperial: '52', metric: '52' },
    '2X': { imperial: '54', metric: '54' },
    '3X': { imperial: '56', metric: '56' },
    '4X': { imperial: '58', metric: '58' },
    '5X': { imperial: '60', metric: '60' },
    '6X': { imperial: '62', metric: '62' },
  },
};

export const sizeChartDataSeamless = {
  BUST: {
    'XS/S': { metric: '81-91', imperial: '32-36' },
    'M/L': { metric: '91-107', imperial: '36-42' },
    'XL/XXL': { metric: '107-117', imperial: '42-46' },
    '1X/2X': { metric: '117-127', imperial: '46-50' },
    '3X/4X': { metric: '127-137', imperial: '50-54' },
    '5X': { metric: '140-150', imperial: '55-59' },
  },
  WAIST: {
    'XS/S': { metric: '64-74', imperial: '25-29' },
    'M/L': { metric: '74-89', imperial: '29-35' },
    'XL/XXL': { metric: '74-99', imperial: '35-39' },
    '1X/2X': { metric: '99-109', imperial: '39-43' },
    '3X/4X': { metric: '109-119', imperial: '43-47' },
    '5X': { metric: '122-132', imperial: '48-52' },
  },
  HIP: {
    'XS/S': { metric: '84-99', imperial: '33-39' },
    'M/L': { metric: '99-112', imperial: '39-44' },
    'XL/XXL': { metric: '112-124', imperial: '44-49' },
    '1X/2X': { metric: '124-135', imperial: '49-53' },
    '3X/4X': { metric: '135-145', imperial: '53-57' },
    '5X': { metric: '147-157', imperial: '58-62' },
  },
  US: {
    'XS/S': { metric: '2-6', imperial: '2-6' },
    'M/L': { metric: '8-10', imperial: '8-10' },
    'XL/XXL': { metric: '12-14', imperial: '12-14' },
    '1X/2X': { metric: '16-18', imperial: '16-18' },
    '3X/4X': { metric: '20-24', imperial: '20-24' },
    '5X': { metric: '26-28', imperial: '26-28' },
  },
  'UK/AUS': {
    'XS/S': { metric: '8-10', imperial: '8-10' },
    'M/L': { metric: '12-16', imperial: '12-16' },
    'XL/XXL': { metric: '18-22', imperial: '18-22' },
    '1X/2X': { metric: '24-28', imperial: '24-28' },
    '3X/4X': { metric: '30-32', imperial: '30-32' },
    '5X': { metric: '34-36', imperial: '34-36' },
  },
  EU: {
    'XS/S': { metric: '36-38', imperial: '36-38' },
    'M/L': { metric: '40-44', imperial: '40-44' },
    'XL/XXL': { metric: '44-50', imperial: '44-50' },
    '1X/2X': { metric: '52-56', imperial: '52-56' },
    '3X/4X': { metric: '56-58', imperial: '56-58' },
    '5X': { metric: '60-62', imperial: '60-62' },
  },
};

if (!config.get('public.brand.showSizeGuide5X6X')) {
  for (const sizes of [
    sizeChartSizesInches,
    sizeChartSizesCm,
    conversionChartSizes,
  ]) {
    sizes.splice(-2, 2);
  }
  for (const data of Object.values(sizeChartDataAllCollections)) {
    delete data['5X'];
    delete data['6X'];
  }
}

export const MODEL_SIZES_MAP = {
  sizeBottom: {
    label: 'Bottom Size',
    key: 'bottomSize',
    rbid: 'bottom_size',
  },
  sizeTop: {
    label: 'Top Size',
    key: 'topSize',
    rbid: 'top_size',
  },
  sizeBra: {
    label: 'Bra Size',
    key: 'braSize',
    rbid: 'bra_size',
  },
  sizeBraBand: {
    label: 'Bra Band',
    key: 'braBand',
    rbid: 'bra_band',
  },
  sizeBraCup: {
    label: 'Bra Cup',
    key: 'braCup',
    rbid: 'bra_cup',
  },
  sizeWaist: {
    label: 'Waist',
    key: 'waist',
    rbid: 'waist_size',
  },
  inseamLength: {
    label: 'Inseam',
    key: 'inseam',
    rbid: 'inseam_length',
  },
};

export const GENDER_SPECIFIC_SIZES = {
  female: ['sizeBra', 'sizeBraCup', 'sizeBraBand'],
};
